
input {
    color: #ffffff;
    text-align: center;
    background-color: #212429;
    border: 1px solid #2C2F36;
    width: 100%;  
    height: 30px;
    font-size: 13px;
    border-radius: 6px;
    padding: 0px 10px;
  }
  
  input:hover {
    border: 1px solid #4A505A;
  }
  
  .custom-layout .react-colorful {
    width: 100%;
    height: 140px;
  }
  
  .custom-layout  .react-colorful__saturation {
    border: 1px solid #2C2F36;
    margin: 0 0 20px 0;
    border-radius: 10px;
  }
  
  .custom-layout  .react-colorful__hue {
    height: 6px;
    margin: 0px 10Px 6PX 10PX;
    border-radius: 7px;
  }


  @media only screen and (min-width: 370px) { 
    input {
      padding: 10px;
      height: 35px;
      font-size: 14px;
    }
    .custom-layout .react-colorful {
      height: 160px;
    }

    .custom-layout  .react-colorful__saturation {
      border-radius: 15px;
    }

    .custom-layout  .react-colorful__hue {
      height: 8px;
      margin: 0px 10Px 6PX 10PX;
      border-radius: 7px;
    }
    

      
  }
  
  @media only screen and (min-width: 500px) {
    input {
        padding: 10px;
        height: 38px;
        font-size: 16px;
      } 
      .custom-layout .react-colorful {
        height: 160px;
      }

    .custom-layout  .react-colorful__hue {
        height: 9px;
        margin: 5px 10px 10px 10px;
        border-radius: 5px;
      }

      .custom-layout  .react-colorful__hue {
        height: 8px;
        margin: 0px 10Px 7PX 10PX;
        border-radius: 7px;
      }
  }

  
  
  .feather-contract {
    
      height: 16px;
      width: 16px;
      stroke: #ffffff80;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
  
      margin: 6px 6px 0px 0px;
  
  }
  
  .nav-link {
    text-decoration: none;
    color: #AAAAAA;
  }
  
  .nav-link :hover {
    color: white;
  }