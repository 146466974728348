.container {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .feather {
    height: 16px;
    width: 16px;
    stroke: #ffffff80;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: #191B1F;
    margin: 0px 6px 6px 0px;
  }

  .feather :hover {  
    fill: #424346;
  }
  
  @media only screen and (min-width: 370px) { 
    .feather {
      margin: 2px 6px 6px 0px;
    }
  }

    
  @media only screen and (min-width: 500px) { 
    .feather {
      margin: 4px 6px 6px 0px;
    }
  }


  .feather-arrow {
    height: 16px;
    width: 16px;

    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    margin: 5px 0px 0px -2px;
  }

  .tooltip {
    z-index: 99999;
    font: Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1.4;
    max-width: 290px;
    background-color: #191B1F;
    border: 1px solid #4A505A;
    color: white;
    padding: 12px;
    border-radius: 15px;
    animation: opacity 0.25s linear;
  }

  .social-menu-button :hover {
    color: white;
  }

 
  .social-menu-link-container {
    display: flex;
    flex-direction: column;
    width: 150px;
    background-color: #212429;
    border: 1px solid #191B1F;
    padding: 4px 10px 8px 10px;
    border-radius: 10px;
    animation: opacity 0.25s linear;
  }


  .social-menu-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav-link-text {
    display: flex;
    justify-content: space-between;
    color:#AAAAAA;
    font: Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1;
    padding: 10px 5px 5px 5px;
  
  }

 
  .arrow {
    width: 0.5rem;
    height: 0.5rem;
  }
  
  .tooltip[data-popper-placement^="right"] > .arrow {
    left: 0px;
  }
  
  .tooltip[data-popper-placement^="left"] > .arrow {
    right: 0px;
  }
  
  .tooltip[data-popper-placement^="top"] > .arrow {
    bottom: 0px;
  }
  
  .tooltip[data-popper-placement^="bottom"] > .arrow {
    top: 0px;
  }
  
  .arrow::before {
    content: "";
    background: #191B1F;
    width: 0.5rem;
    height: 0.5rem;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .tooltiplink a {
    color: #5A94E7;
    text-decoration: none;
    margin-right: 15px;
  }

  .tooltiplink :hover {
    color: #80b2f3;
  }

  .same-line-wrapper{
    display: flex;
    flex-direction: row;
  }
  





